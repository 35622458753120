<template>
  <div>
     <van-dialog
      v-model="showDialog"
      title="游客名单"
      :show-cancel-button="false"
      :show-confirm-button="false"
      close-on-click-overlay
      v-on:close="handleClose"
    >
      <van-row v-for="(item, index) in tour.tourists" :key="'tourist:' + index"
        :style="`background-color:${index % 2 === 0? '#f9f9f9': '#fff'};padding:10px`"
        @click="showArchiveInfo(item.id)">
        <van-row style="font-size:15px">
          <van-col span="22">{{item.name}}</van-col>
          <van-col span="2" style="color:#666666">{{item.gender}}</van-col>
        </van-row>
        <van-row style="font-size:12px;color:#666666">
          电话：{{item.mobile}}
          &nbsp;&nbsp;
          身份证：{{item.id_card}}
        </van-row>
      </van-row>
      <van-row v-if="tour.tourists.length === 0" 
        style="text-align:center;padding:10px;margin-top:30px;background:#efefef">
        <span>当前没有游客参加</span>
      </van-row>
    </van-dialog>
    <van-dialog v-model="showBackup" v-if="archiveInfo.user != null" title="游客备案信息" >
      <van-cell title="游客姓名" :value="archiveInfo.user.username"/>
      <van-cell title="游客电话" :value="archiveInfo.user.mobile"/>
      <van-cell title="备案电话" :value="archiveInfo.mobile"/>
      <van-cell title="目的地" :value="archiveInfo.destination_address"/>
      <van-cell title="入住地址" :value="archiveInfo.live_address"/>
      <van-cell title="备案时间" :value="archiveInfo.created_at"/>
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    tour: {
      type: Object,
      default: () => {}
    }
  },
  data()  {
    return {
      showDialog: this.show,
      showBackup: false,
      archiveInfo: {
        user:{},
        travel_list: {}
      }
    }
  },
  watch: {
    show: {
      handler(val) {
        this.showDialog = val
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('close', false)
    },
    showArchiveInfo(id) {
      const _self = this
      this.$store.dispatch('travel_list/getTouristArchiveInfo', {
        travel_list_id: _self.tour.id,
        tourist_id: id
      }).then((info) => {
        if(info.user == null) {
          Toast('当前游客没有备案')
        } else {
          _self.showBackup = true
          _self.archiveInfo = info
        }
      })
    }
  }
}
</script>

<style>

</style>