<template>
  <div style="min-height:100vh">
    <div class="header">
      <van-row gutter="10">
        <van-col @click="onClickBack">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
            style="height: 25px"
          />
          <span style="font-size: 25px; color: white;font-weight:bold">出团信息</span>
        </van-col>
      </van-row>
    </div>
    <div class="info-content">
      <van-pull-refresh v-model="loading" @refresh="refresh"
        :style="{'padding-bottom': tour.travel_list_nodes.length < 4? '3rem':'0'}">

        <card style="margin-top: 30px; font-size: 14px;">
          <template #content>
            <tourInfoTop :showNodesButton="false" :tour="tour" :titleClickable="false"></tourInfoTop>
            <van-row
              style="height: 20px; color: grey; margin-top: 10px; font-size: 14px"
            >
              出发日期：{{ tour.start_date }}
            </van-row>
            <van-row
              style="color: grey; font-size: 14px"
              @click="toTourInfo(tour.id)"
            >
              <van-col span="18" style="margin-top: 10px">
                团队人数：{{ tour.tourists_count === null? 0: tour.tourists_count }}
              </van-col>
              <van-col span="6">
                <van-button
                  type="info"
                  size="small"
                  style="height: 25px;width:80px;"
                  round
                  color="#395EE8"
                  @click="showTourists = true"
                  >游客名单</van-button
                >
              </van-col>
            </van-row>
            <van-divider dashed class="divider"/>
            <div class="circle-left"></div>
            <div class="circle-right"></div>
            <van-row v-if="tour.travel_list_nodes.length === 0">
              <span style="color:#666666">没有行程节点</span>
            </van-row>
            <van-row>
              <span class="tour_title">
                {{tour.travel_plan.plan_name}}
              </span>
            </van-row>
            <van-steps :active="currentNodeIdx" direction="vertical" active-color="#395EE8" active-icon="clock">
              <van-step v-for="(item, index) in tour.travel_list_nodes" :key="index">
                <van-row @click.native="checkStep(item,index)">
                  <van-col span="20" >
                    <span :style="{'font-weight':600, 'color': tour.status == 3 && index > currentNodeIdx - 1? '#969799': 'inherit'}">
                      {{item.node_name}}
                    </span>
                  </van-col>
                <van-col span="4" style="text-align:right;padding-left:5px">
                    <van-tag round color="#13C2C220" text-color="#13C2C2" style="width:50px"
                      size="medium" v-if="index < currentNodeIdx && currentNodeIdx > 0">已完成</van-tag>
                    <van-tag round color="#3672F920" text-color="#3672F9" 
                      size="medium" v-if="index === currentNodeIdx && tour.status == 1" style="width:50px">进行中</van-tag>
                    <van-tag round  
                      size="medium" type="danger" color="#6f6f6f23" text-color="#979797"
                      v-if="index > currentNodeIdx - 1 && tour.status == 3" 
                      style="width:50px">已取消</van-tag>
                  </van-col>
                </van-row>
                <van-row @click.native="checkStep(item,index)" 
                  :style="{'margin-top':'.1rem', 'color':tour.status == 3 && index > currentNodeIdx - 1? '#969799': 'inherit'}">
                  <span>{{item.node_description}}</span>
                </van-row>
                <van-row @click.native="checkStep(item,index)" 
                  :style="{'margin-top':'.1rem', 'color':tour.status == 3 && index > currentNodeIdx - 1? '#969799': 'inherit'}">
                  <span>开始：{{item.start_time}}</span>
                </van-row>
                <van-row @click.native="checkStep(item,index)" 
                  :style="{'margin-top':'.1rem', 'color':tour.status == 3 && index > currentNodeIdx - 1? '#969799': 'inherit'}">
                  <span>结束：{{item.end_time}}</span>
                </van-row>
              </van-step>
            </van-steps>
          </template>
        </card>
      </van-pull-refresh>
    </div>
    <touristsDialog :show.sync="showTourists" 
      v-on:close="showTourists=false" :tour="tour"/>
    <van-dialog v-model="showCheckinDetail" title="打卡详情" >
      <van-cell title="打卡时间" :value="currentCheckinDetail.created_at"/>
      <van-cell title="情况说明" :value="currentCheckinDetail.note"/>
      <van-cell title="附件">
        <template #label>
          <van-image @click="showPreview(currentCheckinDetail.sign_image_url)" 
            :src="currentCheckinDetail.sign_image_url" fit="cover" height="100" width="100"/>
        </template>
      </van-cell>
    </van-dialog>
    <van-action-sheet v-model="showStepConfirm">
      <van-form @submit="checkin()" ref="checkinFormComp">
      <van-row style="padding:0px 8px 0px 8px;margin-top:-.1rem">
        <van-col span="12" style="text-align:left">
          <van-button type="default" round style="width: 40%" size="small"
             @click="showStepConfirm=false" native-type="button">取消</van-button>
        </van-col>
        <van-col span="12" style="text-align:right">
          <van-button type="info" color="#395EE8" size="small"
            round style="width: 40%" native-type="submit">打卡</van-button>
        </van-col>
      </van-row>
        <van-cell title="更新情况">
          <template #label>
            <van-field
              type="text"
              label=""
              style="width: 50%"
              placeholder="请输入打卡情况"
              v-model="checkinData.note"
            />
          </template>
        </van-cell>
        <van-cell title="附件">
          <template #label>
            <van-field
              name="checkinPhoto"
              v-model="checkinData.sign_image_url"
            >
              <template #input>
                <van-uploader
                  v-model="checkinFormImage"
                  multiple="false"
                  :max-count="1"
                  :after-read="(option) => fnUploadRequest(option)"
                />
              </template>
            </van-field>
          </template>
        </van-cell>
      </van-form>
    </van-action-sheet>
    <div >

    </div>
    <div class="action_bg" v-if="tour.status != 2 && tour.status != 3 ">
      <van-button
        type="info"
        class="action_btn"
        @click="finishTour()"
        v-if="tour.status === 1"
        color="#395EE8"
        >完成行程</van-button
      >
      <van-button
        type="info"
        class="action_btn"
        @click="startTour()"
        v-if="tour.status === 0"
        color="#395EE8"
        >立即出发</van-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/services/apis.js";
import oss from "@/utils/oss";
import { Dialog, Toast, ImagePreview  } from "vant";
import card from "@/views/components/Card";
import tourInfoTop from "@/views/components/TourInfoTop"
import touristsDialog from "@/views/components/TouristsDialog"
export default {
  components: {
    card,
    tourInfoTop,
    touristsDialog
  },
  data() {
    return {
      showStepConfirm: false,
      currentConfirmStep: 0,
      checkinFormImage: [],
      checkinData: {
        travel_list_id: 0,
        travel_plan_node_id: 0,
        note: "",
        lat: "",
        lng: "",
        sign_image_url: "",
      },
      showTourists: false,
      showCheckinDetail: false,
      currentCheckinDetail: {},
      loading: false
    };
  },
  created() {
    this.refresh()
  },
  computed: {
    ...mapGetters("travel_list", {
      tour: "currentTravelList",
      onGoingTours: "onGoingTours",
      checkins: "currentTravelCheckins",
      currentTourNodes: "currentTravelListNodes"
    }),
    currentNodeIdx() {
      if(this.tour.status === 0 && this.checkins.length === 0) {
        return -1
      }
      return this.checkins.length
    },
  },
  mounted() {
    let _self = this;
    let tourId = this.$route.params.id;
    _self.checkinData.travel_list_id = tourId;
  },
  methods: {
    onClickBack() {
      this.$router.go(-1)
    },
    toTourInfo(id) {
      if(this.titleClickable) {
        this.$router.push(`/tour/info/${id}`)
      }
    },
    showPreview(url) {
      ImagePreview({images: [url], closeable: true})
    },
    refresh() {
      const _self = this
      let tourId = this.$route.params.id
      this.$store.dispatch("travel_list/getTravelListInfoById", tourId).then((res) => {
        if(res.status === 200) {
          this.$store.dispatch("travel_list/getTravelCheckins", tourId).then((checkRes) => {
            if(checkRes.status === 200) {
              _self.loading = false
            }
          })

        }
      })
    },
    clearCheckinData() {
      this.checkinData = {
        travel_list_id: 0,
        travel_list_node_id: 0,
        note: "",
        lat: "",
        lng: "",
        sign_image_url: "", 
      }
      this.checkinFormImage = []
    },
    checkStep(node, index) {
      const checkable = this.tour.status == 1;
      this.clearCheckinData()
      if (index === this.checkins.length) {
        if (checkable) {
          this.showStepConfirm = true
          this.checkinData.travel_list_id = this.tour.id
          this.currentConfirmStep = index
          this.checkinData.travel_list_node_id = node.id
        }
      } else if(index < this.checkins.length) {
        this.showCheckinDetail = true
        this.currentCheckinDetail = this.checkins[index]
      } else {
        if(checkable) {
          Toast('请按顺序打卡')
        }
      }
    },
    checkin() {
      let _self = this;
      if(this.checkinData.note !== '' && this.checkinData.note !== null) {
        api.travel_lists_checkin(this.checkinData).then(
          (res) => {
            if (res.data.status === 200) {
              _self.showStepConfirm = false;
              Toast("打卡成功");
              _self.$store.dispatch(
                "travel_list/getTravelCheckins",
                _self.tour.id
              );
              _self.clearCheckinData()
            }
          },
          (err) => {
            console.log(err);
            Toast("打卡失败");
          }
        );
      } else {
        Toast('请输入打卡情况')
      }
    },
    finishTour() {
      const _self = this;
      Dialog.confirm({
        title: "提醒",
        message: "确定结束行程",
      }).then(() => {
        api
          .travel_lists_change_status({
            travel_list_id: _self.tour.id,
            status: 2,
          })
          .then((res) => {
            if (res.data.status === 200) {
              Toast("已完成行程");
              _self.$router.push("/tour");
            }
          });
        // if (_self.checkins.length === _self.tour.travel_list_nodes.length) {
        // } else {
        //   Dialog.alert({
        //     title: "提醒",
        //     message: "还有未完成的打卡，请先完成打卡",
        //   });
        // }
      });
    },
    startTour() {
      const _self = this;
      Dialog.confirm({
        title: "提醒",
        message: "确定开始行程",
      }).then(() => {
        if (_self.onGoingTours.length === 0) {
          api
            .travel_lists_change_status({
              travel_list_id: _self.tour.id,
              status: 1,
            })
            .then((res) => {
              if (res.data.status === 200) {
                Toast("已开始行程");
                _self.$router.push("/tour");
              }
            });
        } else {
          Dialog.alert({
            title: "提醒",
            message: "还有进行中的行程，请先完成该行程",
          });
        }
      });
    },
    async fnUploadRequest(option) {
      const _self = this;
      oss.ossUploadFile(option).then(
        (res) => {
          Toast("上传成功");
          _self.checkinData.sign_image_url = res.fileUrl[0].split("?")[0];
          _self.checkinFormImage.splice(0, 1,  {
            url: res.fileUrl[0].split("?")[0],
            isImage: true,
          })
          if(_self.checkinFormImage.length > 1) {
            _self.checkinFormImage.splice(1, 1)
          }
          console.log(_self.checkinFormImage);
        },
        (err) => {
          console.log("********", err);
          Toast("上传失败");
        }
      );
    },
  },
};
</script>

<style>
.content {
  padding: 16px 16px 160px;
}
.info-content {
  padding: .2rem .2rem 4rem .2rem;
  height: 90vh;
  overflow-y: scroll;
  z-index: 1;
}
.tour_title {
  font-size: .3rem;
  font-weight: bold;
}
.action_btn {
  position: fixed;
  bottom: 10px;
  border-radius: 25px;
  width: 95%;
  height: 50px;
  left: 2.5%;
  text-align: center;
}
.action_bg {
  position: fixed;
  bottom: 0px;
  height: 70px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
  z-index: 500;
}
.circle-left {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-color: #436ef0;
  z-index: 10;
  top: 2.5rem;
  left: -0.2rem;
  border-radius: 50%;
}
.circle-right {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-image: linear-gradient(
    179deg,
    #6c94f6 0%,
    #436ef0 16%,
    #3a5be9 100%
  );
  z-index: 10;
  top: 2.5rem;
  right: -0.2rem;
  border-radius: 50%;
}
</style>